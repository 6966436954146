/* Switch button and text positions for file inputs */

input[type='file']::-webkit-file-upload-button {
  width: 0;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  border: none;
  border: 0px;
}

input[type='file'].spotlight_file_input {
  position: relative;

  color: rgb(107, 114, 128);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5rem;

  height: 34px;
}

x::-webkit-file-upload-button,
input[type='file'].spotlight_file_input:after {
  content: 'Browse';
  -webkit-appearance: button;

  height: 28px;
  position: absolute;
  right: 2px;
  top: 2px;

  display: inline-flex;
  align-items: center;

  padding: 2px 8px;
  border: 0px;
  border-radius: 0.3rem;

  background-color: rgb(243, 244, 246);
  color: rgb(55, 65, 81);
  font-weight: 500;
}

x::-webkit-file-upload-button,
input[type='file'].spotlight_file_input:hover:after {
  background-color: rgba(243, 244, 246, 0.8);
  cursor: pointer;
}
